.About {
    background: rgba(0,0,0,.1) !important;
    color:white;
    font-size: 20px;
}

.About__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
}
  
.About__title {
    position: absolute;
    bottom: 15%;
    right: 10%;
    color: white;
    font-size: 4rem;
    padding: 15px;
}

.About__music {
    width: 80%;
    text-align: center;
}