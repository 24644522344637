.navbar {
    width: 100vw;
    /* max-width: var(--max-width); */
    margin: 0 auto;
    display: flex;
    gap: 2rem;
    font-size: large;
    background-color: rgba(41, 41, 41,.5);
    height: 5vh;
    align-items: center;
    justify-content: start;
    padding-left: 1rem;
    padding-right: 1rem;
    color:white;
}

.navbar__logo {
    height: 10vh;
    cursor: crosshair;
    transition: all 2s ease;
}

/*
  This is the container for all the navbar links ( <ul> )
*/
.navbar__list {
    display: flex;
    list-style: none;
    gap: 2rem;
}

.navbar__menu {
    color: white;
    display: none;
    cursor: pointer;
    margin-left: auto;
}

@media screen and (max-width: 800px) {
    .navbar__list {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        top: 70px;
        left: -100%;
        width: 100%;
        height: 200px;
        position: absolute;
        transition: all 0.5s ease;
        gap: .3rem;
        z-index: -1;
    }

    .navbar__list--active {
        left: 0;
        z-index: 100;
    }

    .navbar__menu {
        display: block;
    }

    .navbar__item {
        width: 95%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0;
        flex: 1;
        /* background: radial-gradient(circle, rgba(255,0,0,1) 1%, rgba(0,0,0,0.01) 60%); */
    }

    .navbar__link {
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin: 0px;
        border-width: 0px;
        opacity: 0.75;
        color: #FFFFFF;
        font-size: 30px;
        /* background: #333333; */
        text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #ff0, 0 -10px 20px #ff8000, 0 -18px 40px #F00;
        transition: all 5s ease;
    }

    .navbar__link:active {
        border-width: 0px;
        /* opacity: 0.9; */
        /* color:violet; */
        font-weight: bold;
        font-size: larger;
    }
    

    .navbar__link:hover {
        border-width: 0px;
        /* color:white; */
        background: radial-gradient(circle, rgba(255,0,0,1) 1%, rgba(0,0,0,.5) 90%,rgba(0,0,0,.1) 100%);
    }

    .active {
        /* color: yellow; */
        font-size: 35px;
        font-weight: bolder;
        transition: all 0.1s ease;
    }
}

@media screen and (min-width: 800px) {
    .navbar__link {
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin: 0px;
        border-width: 0px;
        opacity: 0.75;
        color:white;
        font-size: 1.3rem;
    }

    .navbar__link:active {
        border-width: 2px;
        opacity: 0.9;
        color:green !important;
        font-weight: bold;
        font-size: larger;
    }
    
}
