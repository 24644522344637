.Main {
    /* background-color: aqua; */
    justify-content: end !important;
    padding-bottom: 60px !important;
}
  
@media screen and (min-width: 800px) {
    /*DESKTOP*/
    .linksW3 {
        width: 100vw;
        height: 10vh;
        display: flex;
        flex-direction: row;
        padding-top: 1rem;
    }

    .w3From {
        flex: 1;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .w3From img {
        width:  5rem;
        object-fit:scale-down;
        transition: all 1s ease;
    }

    .w3From img:hover {
        width:  8rem;
        object-fit:scale-down;
    }

    .Main__image {
        flex: 1;
        width: 100px;
        height: 100px;
        object-fit: cover;
    }
    
  }
  
  @media screen and (max-width: 800px) {
    /*MOBILE*/

    .linksW3 {
        width: 100vw;
        height: 10vh;
        display: flex;
        flex-direction: row;
        padding-top: 1rem;
    }

    .w3From {
        flex: 1;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .w3From img {
        width:  5rem;
        object-fit:scale-down;
        transition: all 1s ease;
    }

    .w3From img:hover {
        width:  8rem;
        object-fit:scale-down;
    }
  }