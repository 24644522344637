* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* font-family: "Montserrat", sans-serif; */
}

.section {
  position: relative;
  width: 100vw;
  height:90vh;
  display: flex;
  flex-direction: column;
  align-items:center;
  justify-content: center;
  background: url('./images/willi3.png');
  background-size: 100%;
  background-position: center;
  transition: all 5s ease;
  /* opacity: .01; */
}


/* 
.section:before{
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: url('./images/triangulos.jpg');
  background-size: 100%;
  opacity: .5;
  mix-blend-mode: hard-light;
}  */

/* .section:hover {
  animation: animacion .1 linear infinite;
  background-size: 100%;
  opacity: 1;
}

@keyframes animacion {
  0%{
    background-position: 0 0;
    filter: hue-rotate(0deg);
  }

  30%{
    background-position: -10 20;
    filter: hue-rotate(0deg);
  }

  60%{
    background-position: 20 -20;
    filter: hue-rotate(0deg);
  }

  100%{
    background-position: -50 10;
    filter: hue-rotate(90deg);
  }
} */