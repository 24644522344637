*,
::after,
::before {
  box-sizing: border-box;
}

/* fonts */
/* @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600&family=Montserrat&display=swap"); */

html {
  font-size: 100%;
  height: 100vh;
  width: 100vw;
} /*16px*/


body {
  height: 100vh;
  width: 100vw;
  /* background: var(--backgroundColor); */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  /* font-weight: 400; */
  /* line-height: 1.75; */
  /* color: var(--textColor); */
  background-color: black;
  overflow: hidden; 

}

.footer{
  height:5vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer__title {
  font-weight: bold;
  color: white;
}

@media screen and (min-width: 800px) {
  .footer_wing {
    /* width: 10vw; */
    height: 4vh;
  }
}

@media screen and (max-width: 800px) {
  .footer_wing {
    height: 4vh;
  }
}

